.categories {
  margin-left: 2px;
  margin-right: 10px;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  padding-left: 5px;
  width: 15%;
  min-height: 40px;
  margin-bottom: auto;
  margin-top: auto;
}

.categories:focus {
  outline: none !important;
  border:1px solid #11948c;
  box-shadow: 0 0 10px #009F97;
}

.responsive {
  composes: flexLeft from '../../App.module.css';
  display: none;
}

.header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 500;
}

.inputSearch {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  width: 90%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-radius: 0.25 rem;
}

.inputSearch:focus {
  outline: none !important;
  border:1px solid #11948c;
  box-shadow: 0 0 10px #009F97;
}

.navBarContainer {
  background-color: white;
  color: #009F97;
  height: 70px;
  align-items: center;
  display: flex;
  border-top-width: 8px;
  border-top-style: solid;
  border-top-color: #009F97;
}

.navBarItem {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 10px;
}

.searchBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 80%;
  max-height: 40px;
  margin-bottom: auto;
  margin-top: auto;
}

.searchButton {
  color: #009F97;
  background-color: transparent;
  border: 1px solid #009F97;
  padding: 0.375rem 0.75rem;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width:9%;
}

.searchButton:hover {
  color: white;
  background-color: #009F97;
}

.formSearch {
  composes: flexCenter from '../../App.module.css';
  width: 70%;
}

.shoppingCart {
  color: #009F97;
  margin-bottom: auto;
  margin-top: auto;
}

.shoppingCart:hover {
  margin-bottom: auto;
  margin-top: auto;
  color: #11948c;
}

.shoppingCartCounter {
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  color: white;
  background-color: #009F97;
  animation: blinker 1s linear infinite;
}

.dropdownItem {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
}

.faBars {
  font-size: 20px;
  margin-right: 15px;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}
