.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center !important;
  max-height: 535px;
  margin-bottom: 10px;
}

.gridItem1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3 ;
}

.gridItem2 {
  grid-column: 3;
  grid-row: 1;
}

.gridItem3 {
  grid-column: 3;
  grid-row: 2;
}

.titleIcon {
  margin: 0 10px 0 5px;
}

.multiCarouselContainer {
  padding-top: 10px;
}
