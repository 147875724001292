.footerContainer {
  background-color: #FAF7F3;
  padding-bottom: 20px;
}

.payuImg {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.paymentList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 10px 0;
  margin-bottom: 10px;
  padding: 0;
}

.logoList {
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  list-style-type: none;
  max-width: 700px;
}

.logoContainer {
  display: list-item;
  text-align: -webkit-match-parent;
  margin: 38px 15px 0;
}

.logoItem {
  width: 70px;
  height: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.icon {
  max-width: 100%;
}

.whatsappSection {
  font-size: 11px;
}

.footerNavItem {
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 500px) {
  .footerNav {
    display: block;
    text-align: center;
  }
  .footerNavItem {
    display: block;
  }
}

