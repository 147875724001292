.container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center !important;
  max-width: 1080px;
  flex-wrap: wrap;
}

.cardContainer {
  overflow: hidden;
  margin: 0 20px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center !important;
  max-height: 535px;
  margin-bottom: 10px;
}

.gridItem1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3 ;
}

.gridItem2 {
  grid-column: 3;
  grid-row: 1;
}

.gridItem3 {
  grid-column: 3;
  grid-row: 2;
}

.boldEmptySection {
  display: block;
  -ms-flex: 1;
  flex: 1;
  height: 2px;
  opacity: .1;
  background-color: black;
  margin: 0px 10px 0px 10px;
}

.card {
  width: 100%;
  opacity: 0.85;
  overflow: hidden;
  transition: transform ease 0.3s;
  max-height: 400px;
}

.card:hover {
  opacity: 1;
  transform: scale(1.2);
}

.whiteLink {
  color: white;
  text-decoration: unset;
  cursor: pointer;
}

.whiteLink:hover {
  color: pink;
  cursor: pointer;
}

.link {
  color: #009F97;
  text-decoration: unset;
  cursor: pointer;
}

.link:hover {
  color: pink;
  cursor: pointer;
}

.flexLeft {
  display: flex;
  margin-right: auto;
}

.flexCenter {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.flexRight {
  display: flex;
  margin-left: auto;
}

.icons {
  margin-left: 0.3em;
  margin-right: 0.3em;
  font-size: 18px;
}

.dropDown {
  background-color: #009F97;
}

.verticalAlign {
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
}

.tooltip {
  font-size: 9px !important;
}

.locationList {
  margin-right: 20px;
  margin-bottom: auto;
  margin-top: auto;
  color: #009F97;
}

.bannerImage {
  background-image: url("./static/images/location-banner.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50%;
  min-height: 250px;
  margin-bottom: 20px;
}

.itemCarouselClass {
  height: fit-content;
  overflow: auto important!;
}

.infoContainer {
  color: #009F97;
  font-size: 10px;
}

.titleSection {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 30px 0px;
  color: #009F97;
  font-size: 16px;
}

.teamMembers {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: center;
  margin-top: 20px;
}

.teamLabel {
  max-width: 200px;
  color: #009F97;
  margin: 0 auto;
  justify-content: space-between;
}

@keyframes animatedBackground {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 0 0%;
  }
}

.backgroundContainer {
  background-image: url("./static/images/background.png");
  background-repeat: repeat;
  background-position: 0px 0px;
  animation: animatedBackground 100s linear infinite;
}


@media (max-width: 1080px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .locationList {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .bannerImage {
    min-height: 200px;
  }

  .gridContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 750px) {
  .bannerImage {
    min-height: 160px;
  }
}

@media (max-width: 600px) {
  .gridItem1 {
    grid-column: 1 / 4;
    grid-row: 1 / 3 ;
  }
  .gridItem2 {
    display: none;
  }
  .gridItem3 {
    display: none;
  }
}
